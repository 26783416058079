export function len(o) {
    if (!o || !o.length) {
        return 0;
    }
    return o.length;
}

function mkel(s) {
    return document.createElement(s);
}

function onId(id, f) {
    const el = document.getElementById(id);
    if (!el) {
        console.log("onId: no element with id:", id);
        return;
    }
    f(el);
}

export function preventDefaults(e) {
    //console.log("preventDefaults");
    e.preventDefault()
    e.stopPropagation()
}

export function preventDragOnElement(el) {
    //console.log("preventDragOnElement", el);
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        el.addEventListener(eventName, preventDefaults, false)
    })
}

// TODO: very primitive, doesn't work for every word
export function plural(n, s) {
    if (n == 1) {
        return s;
    }
    return s + "s";
}

export function humanizeSize(n) {
    const a = [
        [1024 * 1024 * 1024 * 1024, "TB"],
        [1024 * 1024 * 1024, "GB"],
        [1024 * 1024, "MB"],
        [1024, "kB"],
    ];
    for (const el of a) {
        const size = el[0];
        if (n >= size) {
            // @ts-ignore
            let s = (n / size).toFixed(2);
            return s.replace(".00", "") + " " + el[1];
        }
    }
    return `${n} B`;
}

export function formatDurSince(timeStart) {
    const end = +new Date();
    const durMs = end - timeStart;
    return formatDur(durMs);
}

export function formatDur(durMs) {
    if (durMs < 1000) {
        return `${durMs} ms`;
    }
    let secs = (durMs / 1000).toFixed(2);
    secs = secs.replace(".00", "");
    return `${secs} s`;
}

// Wrap readEntries in a promise to make working with readEntries easier
export async function readEntriesPromise(directoryReader) {
    try {
        return await new Promise((resolve, reject) => {
            directoryReader.readEntries(resolve, reject);
        });
    } catch (err) {
        console.log(err);
    }
}

export async function collectAllDirectoryEntries(directoryReader, queue) {
    let readEntries = await readEntriesPromise(directoryReader);
    while (readEntries.length > 0) {
        queue.push(...readEntries);
        readEntries = await readEntriesPromise(directoryReader);
    }
}

export async function getAllFileEntries(dataTransferItemList) {
    let fileEntries = [];
    let queue = [];
    let n = dataTransferItemList.length;
    for (let i = 0; i < n; i++) {
        let item = dataTransferItemList[i];
        let entry = item.webkitGetAsEntry();
        queue.push(entry);
    }
    while (len(queue) > 0) {
        let entry = queue.shift();
        if (entry.isFile) {
            fileEntries.push(entry);
        } else if (entry.isDirectory) {
            let reader = entry.createReader();
            await collectAllDirectoryEntries(reader, queue);
        }
    }
    return fileEntries;
}

export function filterFiles(files, fnAllowed) {
    let filesWithPath = [];
    for (const file of files) {
        if (fnAllowed && !fnAllowed(file.name)) {
            console.log(`${file.name} is not a supported file type`)
            continue;
        }

        let fileWithPath = {
            file: file,
            path: file.name,
        }
        filesWithPath.push(fileWithPath);
    }
    return filesWithPath;
}

export async function filterDataTransferEntries(dt, fnAllowed) {
    let fileEntries = await getAllFileEntries(dt.items);
    // convert to File objects
    let filesWithPath = [];
    for (let fe of fileEntries) {
        let path = fe.fullPath;
        if (fnAllowed && !fnAllowed(path)) {
            console.log(`${path} is not a supported file type`)
            continue;
        }
        let file = await new Promise((resolve, reject) => {
            fe.file(resolve, reject);
        })
        path = path.replace(/^\//, "");
        let fileWithPath = {
            file: file,
            path: path,
        }
        filesWithPath.push(fileWithPath);
    }
    return filesWithPath;
}
